import {
  forwardRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  Ref,
} from "react"
import styled from "styled-components"

import {
  InputContainer,
  TInputContainerProps,
} from "src/ui/InputContainer/InputContainer"

export type TMTextFieldAcceptedTypes =
  | "text"
  | "number"
  | "search"
  | "email"
  | "url"
  | "tel"

type THTMLInputAttributes = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange"
>

export type TMTextFieldProps = THTMLInputAttributes & {
  type?: Extract<HTMLInputTypeAttribute, TMTextFieldAcceptedTypes>
  value: string
  placeholder?: string
  ariaLabel?: string
  onChange: (value: string) => void
  disabled?: boolean
} & TInputContainerProps

function _MTextField(
  {
    type = "text",
    value,
    label,
    placeholder,
    ariaLabel,
    onChange,
    onClear,
    error,
    helperText,
    disabled,
    startAdornment,
    endAdornment,
    requiredIndicator,
    ...props
  }: TMTextFieldProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <InputContainer
      label={label}
      error={error}
      showClearButton={!!onClear && !!value}
      onClear={onClear}
      helperText={helperText}
      disabled={disabled}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      requiredIndicator={requiredIndicator}
      cursor="text"
    >
      <StyledInput
        ref={ref}
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        aria-label={error || ariaLabel || label}
        aria-invalid={!!error}
        disabled={disabled}
        {...props}
      />
    </InputContainer>
  )
}

export const MTextField = forwardRef<HTMLInputElement, TMTextFieldProps>(
  _MTextField
)

const StyledInput = styled.input`
  all: unset;
  width: 100%;
`
