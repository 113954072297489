import styled, { css } from "styled-components"

import { ButtonStyleReset } from "src/ui/Button/MButton"
import { colorScale, colorsV2 } from "src/ui/colors"
import XIcon from "src/ui/icons/x.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export type TInputContainerProps = {
  label?: string
  clearAriaLabel?: string
  showClearButton?: boolean
  onClear?: () => void
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  shrink?: boolean
  error?: string
  helperText?: string
  cursor?: string
  children?: React.ReactNode
  disabled?: boolean
  requiredIndicator?: boolean
  /** This is only needed for components that does not render any interactive component in `children`, otherwise this will not be focusable */
  tabIndex?: number
}

/*
  This component should not be used by high-level components, 
  only by wrappers such as MTextField and MSelect.
*/
export function InputContainer({
  label,
  clearAriaLabel,
  showClearButton,
  onClear,
  startAdornment,
  endAdornment,
  shrink,
  error,
  helperText,
  children,
  cursor,
  disabled,
  tabIndex,
  requiredIndicator,
}: TInputContainerProps) {
  return (
    <Container>
      <Labelled
        $error={!!error}
        $cursor={cursor}
        $disabled={disabled}
        tabIndex={tabIndex}
      >
        {startAdornment}

        <FieldWrapper>
          <LabelWrapper>
            <FloatingLabel $shrink={shrink}>
              {label}
              {requiredIndicator && "*"}
            </FloatingLabel>
          </LabelWrapper>
          <InputWrapper $hasLabel={!!label} $disabled={disabled}>
            {children}
          </InputWrapper>
        </FieldWrapper>

        {(showClearButton || endAdornment) && (
          <EndWrapper>
            {showClearButton && (
              <ClearButton
                onClick={onClear}
                aria-label={clearAriaLabel}
                disabled={disabled}
              >
                <XIcon width={12} />
              </ClearButton>
            )}

            {endAdornment}
          </EndWrapper>
        )}
      </Labelled>

      <BottomWrapper>
        {error && (
          <MText variant="bodyS" color="emergency">
            {error}
          </MText>
        )}

        {helperText && (
          <MText variant="bodyS" color="secondary">
            {helperText}
          </MText>
        )}
      </BottomWrapper>
    </Container>
  )
}

const shrunkLabelCss = css`
  font-size: 0.825rem;
  transform: translateY(-100%);
`

const LabelWrapper = styled.div`
  position: relative;
  /* 
    The minimum height of the container is 60px or 3.75rem, 
    this makes sure that when there only is one line of text (regardless of line-height) the label will be centered
  */
  top: 1.875rem;
`

const FloatingLabel = styled.span<{ $shrink?: boolean }>`
  position: absolute;
  transform: translateY(-50%);
  font-size: 1rem;
  transition: all 0.2s;
  white-space: nowrap;
  line-height: 1.5rem;

  ${({ $shrink }) => $shrink && shrunkLabelCss}
`

const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
`

const InputWrapper = styled.div<{ $hasLabel: boolean; $disabled?: boolean }>`
  flex: 1;
  padding-top: ${({ $hasLabel }) => ($hasLabel ? `1.75rem` : spacing.M)};
  padding-bottom: ${({ $hasLabel }) =>
    $hasLabel ? `${spacing.XS}` : spacing.M};
  color: ${({ $disabled }) =>
    $disabled ? colorScale.koti[400] : colorsV2.textPrimary};
  min-height: 1.5rem;
  overflow: hidden;
`

const ClearButton = styled.button`
  ${ButtonStyleReset}
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  color: ${colorsV2.textPrimary};

  &:focus {
    outline-color: ${colorScale.gaff[600]};
    outline-offset: 2px;
    outline-width: 1px;
    outline-style: dashed;
  }

  &:disabled {
    color: ${colorScale.koti[400]};
    cursor: auto;
  }
`
const EndWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

const Labelled = styled.label<{
  $error: boolean
  $cursor?: string
  $disabled?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: ${spacing.M};
  padding-inline: ${spacing.M};
  background-color: ${colorsV2.neutral};
  border: 1px solid
    ${({ $error }) => ($error ? colorsV2.systemErrorDark : colorsV2.neutral)};
  border-radius: 8px;
  cursor: ${({ $disabled, $cursor }) =>
    $disabled ? "default" : ($cursor ?? "pointer")};
  outline: none;

  color: ${({ $disabled }) =>
    !$disabled ? colorsV2.textTertiary : colorScale.koti[400]};

  &:focus-within {
    border-color: ${({ $error }) =>
      $error ? colorsV2.systemErrorDark : colorsV2.primary};
  }

  &:has(input:not([value=""])),
  // Looking at the value is not as stable with textarea
  &:has(textarea:not(:empty)),
  &:has(select option:checked:not([value=""])),
  &:has(input:placeholder-shown),
  &:has(textarea:placeholder-shown),
  &:focus-within {
    ${FloatingLabel} {
      ${shrunkLabelCss}
    }
  }
`

const BottomWrapper = styled.div`
  padding-left: ${spacing.M};
`

const Container = styled.div`
  width: 100%;
  min-width: 0;
`
